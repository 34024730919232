import React, {Fragment} from 'react'
import Link from 'next/link'
import {signIn} from 'next-auth/client'

const Join = () => {
  return (
    <Fragment>
      <div className="pt-8 my-5">
        <div className="space-y-0 space-y-1"></div>
        <div className="space-y-0 space-y-1"></div>
        <div className="space-y-0 space-y-1"></div>
      </div>
      <div className="relative px-4 py-16 sm_px-6 sm_py-24 lg_py-48 lg_px-8">
        <h1 className="text-3xl font-extrabold tracking-tight text-center sm_text-4xl lg_text-6xl">
          <span className="block text-white">
            Join the Devolver Beta Squadron
          </span>
        </h1>
        <p className="max-w-lg mx-auto mt-6 text-xl text-center text-red-100">
          Play unreleased games, help their creators improve them and make your
          friends jealous.
        </p>
        <p className="max-w-lg mx-auto mt-6 text-xl text-center text-red-100">
          Get started by completing our short application.
        </p>
        <div className="max-w-sm mx-auto mt-10 sm_mx-w-none sm_flex sm_justify-center">
          <div className="sm_mx-auto">
            <Link
              href="/apply"
              className="flex items-center justify-center px-4 py-3 text-base font-medium text-white bg-black border border-transparent cursor-pointer hover_bg-white hover_text-black sm_px-8 transition duration-150 ease-in-out">
              
                Apply Now
              
            </Link>
            <p className="mt-2 text-sm text-center text-red-100 max-w">
              or{' '}
              <a
                onClick={(e) => {
                  e.preventDefault()
                  signIn(null, {
                    callbackUrl: '/next',
                  })
                }}
                className="font-medium text-white underline cursor-pointer hover_text-black transition durantion-150 ease-in-out"
              >
                sign with email or social accounts.
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Join
